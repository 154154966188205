@font-face {
  font-family: 'ta-icons';
  src: url('#{$ta-font-path}/ta-icons.eot?041220241422');
  src: url('#{$ta-font-path}/ta-icons.eot?041220241422#iefix') format('embedded-opentype'),
       url('#{$ta-font-path}/ta-icons.woff2?041220241422') format('woff2'),
       url('#{$ta-font-path}/ta-icons.woff?041220241422') format('woff'),
       url('#{$ta-font-path}/ta-icons.ttf?041220241422') format('truetype'),
       url('#{$ta-font-path}/ta-icons.svg?041220241422#ta-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="ta-icon-"]:before, [class*=" ta-icon-"]:before {
  font-family: "ta-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ta-icon-car:before {
  content: "\e00";
}
.ta-icon-truck:before {
  content: "\e001";
}
.ta-icon-motorcycle:before {
  content: "\e002";
}
.ta-icon-car-front:before {
  content: "\e003";
}
.ta-icon-truck-front:before {
  content: "\e004";
}
.ta-icon-motorcycle-front:before {
  content: "\e005";
}
.ta-icon-engine:before {
  content: "\e006";
}
.ta-icon-drive-train:before {
  content: "\e007";
}
.ta-icon-steering:before {
  content: "\e008";
}
.ta-icon-suspension:before {
  content: "\e009";
}
.ta-icon-exhaust:before {
  content: "\e010";
}
.ta-icon-brake:before {
  content: "\e011";
}
.ta-icon-interior-equipment:before {
  content: "\e012";
}
.ta-icon-fuel-mixture-formation:before {
  content: "\e013";
}
.ta-icon-axle:before {
  content: "\e014";
}
.ta-icon-window-cleaning:before {
  content: "\e015";
}
.ta-icon-electrics:before {
  content: "\e016";
}
.ta-icon-spark-glow-ignition:before {
  content: "\e017";
}
.ta-icon-filter:before {
  content: "\e018";
}
.ta-icon-lights:before {
  content: "\e019";
}
.ta-icon-cooling-ac:before {
  content: "\e020";
}
.ta-icon-chemical-products:before {
  content: "\e021";
}
.ta-icon-special-tools:before {
  content: "\e022";
}
.ta-icon-workshop-equipment:before {
  content: "\e023";
}
.ta-icon-work-protection:before {
  content: "\e024";
}
.ta-icon-accessories:before {
  content: "\e025";
}
.ta-icon-motorcycle-special-tool:before {
  content: "\e026";
}
.ta-icon-tools:before {
  content: "\e027";
}
.ta-icon-trailer-hitch:before {
  content: "\e028";
}
.ta-icon-maintenance-service:before {
  content: "\e029";
}
.ta-icon-body-truck:before {
  content: "\e030";
}
.ta-icon-body-car:before {
  content: "\e031";
}
.ta-icon-body-motorcycle:before {
  content: "\e032";
}
.ta-icon-fitting-position-front-left:before {
  content: "\e033";
}
.ta-icon-fitting-position-front-right:before {
  content: "\e034";
}
.ta-icon-fitting-position-rear-left:before {
  content: "\e035";
}
.ta-icon-fitting-position-rear-right-1:before {
  content: "\e036";
}
.ta-icon-fitting-position-rear-right:before {
  content: "\e037";
}
.ta-icon-fitting-position-front:before {
  content: "\e038";
}
.ta-icon-fitting-position-all:before {
  content: "\e039";
}
.ta-icon-repair-time:before {
  content: "\e040";
}
.ta-icon-technical-manuals:before {
  content: "\e041";
}
.ta-icon-technical-data:before {
  content: "\e042";
}
.ta-icon-diagnostic-data:before {
  content: "\e043";
}
.ta-icon-wiring-diagrams:before {
  content: "\e044";
}
.ta-icon-interactive-wiring-diagrams:before {
  content: "\e91b";
}
.ta-icon-fuse-relay:before {
  content: "\e046";
}
.ta-icon-paintwork:before {
  content: "\e047";
}
.ta-icon-copy:before {
  content: "\e048";
}
.ta-icon-heating-ac:before {
  content: "\e049";
}
.ta-icon-transmission:before {
  content: "\e050";
}
.ta-icon-steering-suspension:before {
  content: "\e051";
}
.ta-icon-exterior-interior-truck:before {
  content: "\e052";
}
.ta-icon-exterior-interior-car:before {
  content: "\e053";
}
.ta-icon-light-commercial-vehicle:before {
  content: "\e054";
}
.ta-icon-user:before {
  content: "\e055";
}
.ta-icon-user-log:before {
  content: "\e056";
}
.ta-icon-key:before {
  content: "\e057";
}
.ta-icon-no-image:before {
  content: "\e058";
}
.ta-icon-camera:before {
  content: "\e059";
}
.ta-icon-manufactor:before {
  content: "\e060";
}
.ta-icon-dealer:before {
  content: "\e061";
}
.ta-icon-save:before {
  content: "\e062";
}
.ta-icon-visible:before {
  content: "\e063";
}
.ta-icon-invisible:before {
  content: "\e064";
}
.ta-icon-criteria:before {
  content: "\e065";
}
.ta-icon-shopping-cart:before {
  content: "\e066";
}
.ta-icon-cart-history:before {
  content: "\e332";
}
.ta-icon-add-cart:before {
  content: "\e067";
}
.ta-icon-remove-cart:before {
  content: "\e068";
}
.ta-icon-search-by-car:before {
  content: "\e069";
}
.ta-icon-search-by-truck:before {
  content: "\e070";
}
.ta-icon-search-by-number:before {
  content: "\e071";
}
.ta-icon-search-by-engine:before {
  content: "\e072";
}
.ta-icon-id-check:before {
  content: "\e073";
}
.ta-icon-scan-barcode:before {
  content: "\e074";
}
.ta-icon-link:before {
  content: "\e075";
}
.ta-icon-search:before {
  content: "\e076";
}
.ta-icon-home:before {
  content: "\e077";
}
.ta-icon-edit:before {
  content: "\e078";
}
.ta-icon-delete:before {
  content: "\e079";
}
.ta-icon-master-data:before {
  content: "\e080";
}
.ta-icon-master-data-full:before {
  content: "\e081";
}
.ta-icon-master-data-half:before {
  content: "\e082";
}
.ta-icon-master-data-add:before {
  content: "\e083";
}
.ta-icon-master-data-price:before {
  content: "\e084";
}
.ta-icon-document-finished:before {
  content: "\e085";
}
.ta-icon-document-delivered:before {
  content: "\e086";
}
.ta-icon-configuration:before {
  content: "\e087";
}
.ta-icon-microphone:before {
  content: "\e088";
}
.ta-icon-dashboard:before {
  content: "\e089";
}
.ta-icon-wheels-tyres:before {
  content: "\e090";
}
.ta-icon-general:before {
  content: "\e091";
}
.ta-icon-main-group:before {
  content: "\e092";
}
.ta-icon-subgroup:before {
  content: "\e093";
}
.ta-icon-process:before {
  content: "\e094";
}
.ta-icon-clock:before {
  content: "\e095";
}
.ta-icon-repair:before {
  content: "\e096";
}
.ta-icon-thumbs-down:before {
  content: "\e097";
}
.ta-icon-thumbs-up:before {
  content: "\e098";
}
.ta-icon-mail-alt:before {
  content: "\e101";
}
.ta-icon-star:before {
  content: "\e102";
}
.ta-icon-star-empty:before {
  content: "\e103";
}
.ta-icon-th-large:before {
  content: "\e104";
}
.ta-icon-th:before {
  content: "\e105";
}
.ta-icon-th-list:before {
  content: "\e106";
}
.ta-icon-ok:before {
  content: "\e107";
}
.ta-icon-ok-circled:before {
  content: "\e108";
}
.ta-icon-ok-squared:before {
  content: "\e109";
}
.ta-icon-cancel:before {
  content: "\e110";
}
.ta-icon-cancel-circled:before {
  content: "\e111";
}
.ta-icon-window-close:before {
  content: "\e112";
}
.ta-icon-plus:before {
  content: "\e113";
}
.ta-icon-plus-circled:before {
  content: "\e114";
}
.ta-icon-plus-squared:before {
  content: "\e115";
}
.ta-icon-plus-squared-alt:before {
  content: "\e116";
}
.ta-icon-minus:before {
  content: "\e117";
}
.ta-icon-minus-circled:before {
  content: "\e118";
}
.ta-icon-minus-squared:before {
  content: "\e119";
}
.ta-icon-minus-squared-alt:before {
  content: "\e120";
}
.ta-icon-help-circled:before {
  content: "\e121";
}
.ta-icon-info-circled:before {
  content: "\e122";
}
.ta-icon-lock:before {
  content: "\e123";
}
.ta-icon-lock-open-alt:before {
  content: "\e124";
}
.ta-icon-tag:before {
  content: "\e125";
}
.ta-icon-tags:before {
  content: "\e126";
}
.ta-icon-download-cloud:before {
  content: "\e127";
}
.ta-icon-upload-cloud:before {
  content: "\e128";
}
.ta-icon-business-cloud:before {
  content: "\e91c";
}
.ta-icon-bell-alt:before {
  content: "\e129";
}
.ta-icon-attention-alt:before {
  content: "\e130";
}
.ta-icon-attention:before {
  content: "\e131";
}
.ta-icon-attention-circled:before {
  content: "\e132";
}
.ta-icon-doc-inv:before {
  content: "\e133";
}
.ta-icon-doc-text-inv:before {
  content: "\e134";
}
.ta-icon-file-pdf:before {
  content: "\e135";
}
.ta-icon-file-word:before {
  content: "\e136";
}
.ta-icon-file-excel:before {
  content: "\e137";
}
.ta-icon-file-image:before {
  content: "\e138";
}
.ta-icon-picture:before {
  content: "\e139";
}
.ta-icon-folder:before {
  content: "\e140";
}
.ta-icon-rss:before {
  content: "\e141";
}
.ta-icon-rss-squared:before {
  content: "\e142";
}
.ta-icon-phone:before {
  content: "\e143";
}
.ta-icon-cog:before {
  content: "\e144";
}
.ta-icon-cog-alt:before {
  content: "\e145";
}
.ta-icon-down-open:before {
  content: "\e146";
}
.ta-icon-up-open:before {
  content: "\e147";
}
.ta-icon-left-open:before {
  content: "\e148";
}
.ta-icon-right-open:before {
  content: "\e149";
}
.ta-icon-down-dir:before {
  content: "\e150";
}
.ta-icon-up-dir:before {
  content: "\e151";
}
.ta-icon-left-dir:before {
  content: "\e152";
}
.ta-icon-right-dir:before {
  content: "\e153";
}
.ta-icon-arrows-cw:before {
  content: "\e154";
}
.ta-icon-shuffle:before {
  content: "\e155";
}
.ta-icon-expand:before {
  content: "\e156";
}
.ta-icon-collapse:before {
  content: "\e157";
}
.ta-icon-expand-right:before {
  content: "\e158";
}
.ta-icon-collapse-left:before {
  content: "\e159";
}
.ta-icon-align-left:before {
  content: "\e160";
}
.ta-icon-align-center:before {
  content: "\e161";
}
.ta-icon-align-right:before {
  content: "\e162";
}
.ta-icon-align-justify:before {
  content: "\e163";
}
.ta-icon-list:before {
  content: "\e164";
}
.ta-icon-indent-left:before {
  content: "\e165";
}
.ta-icon-indent-right:before {
  content: "\e166";
}
.ta-icon-list-bullet:before {
  content: "\e167";
}
.ta-icon-chart-bar:before {
  content: "\e168";
}
.ta-icon-chart-area:before {
  content: "\e169";
}
.ta-icon-chart-pie:before {
  content: "\e170";
}
.ta-icon-youtube-squared:before {
  content: "\e171";
}
.ta-icon-youtube-play:before {
  content: "\e172";
}
.ta-icon-twitter-squared:before {
  content: "\e173";
}
.ta-icon-twitter:before {
  content: "\e174";
}
.ta-icon-linkedin-squared:before {
  content: "\e175";
}
.ta-icon-linkedin:before {
  content: "\e176";
}
.ta-icon-facebook:before {
  content: "\e177";
}
.ta-icon-facebook-squared:before {
  content: "\e178";
}
.ta-icon-trademark:before {
  content: "\e179";
}
.ta-icon-registered:before {
  content: "\e180";
}
.ta-icon-sort-alt-up:before {
  content: "\e182";
}
.ta-icon-sort-alt-down:before {
  content: "\e183";
}
.ta-icon-sort:before {
  content: "\e184";
}
.ta-icon-sort-down:before {
  content: "\e185";
}
.ta-icon-sort-up:before {
  content: "\e186";
}
.ta-icon-check:before {
  content: "\e187";
}
.ta-icon-check-empty:before {
  content: "\e188";
}
.ta-icon-toggle-off:before {
  content: "\e189";
}
.ta-icon-toggle-on:before {
  content: "\e190";
}
.ta-icon-circle-empty:before {
  content: "\e191";
}
.ta-icon-circle:before {
  content: "\e192";
}
.ta-icon-adjust:before {
  content: "\e193";
}
.ta-icon-logout:before {
  content: "\e194";
}
.ta-icon-login:before {
  content: "\e195";
}
.ta-icon-location:before {
  content: "\e196";
}
.ta-icon-sliders:before {
  content: "\e197";
}
.ta-icon-xing:before {
  content: "\e198";
}
.ta-icon-xing-squared:before {
  content: "\e199";
}
.ta-icon-signal:before {
  content: "\e200";
}
.ta-icon-belt-drive:before {
  content: "\e201";
}
.ta-icon-clutch-parts:before {
  content: "\e202";
}
.ta-icon-carrier-equipment:before {
  content: "\e203";
}
.ta-icon-comfort-systems:before {
  content: "\e204";
}
.ta-icon-compressed-air-system:before {
  content: "\e205";
}
.ta-icon-headlight-washer-system:before {
  content: "\e206";
}
.ta-icon-electric-drive:before {
  content: "\e207";
}
.ta-icon-hybrid:before {
  content: "\e208";
}
.ta-icon-axle-drive:before {
  content: "\e209";
}
.ta-icon-air-conditioning:before {
  content: "\e210";
}
.ta-icon-operating-fluids-:before {
  content: "\e211";
}
.ta-icon-locking-system:before {
  content: "\e212";
}
.ta-icon-communication-systems:before {
  content: "\e213";
}
.ta-icon-maintenance-service-parts:before {
  content: "\e214";
}
.ta-icon-security-systems:before {
  content: "\e215";
}
.ta-icon-heater:before {
  content: "\e216";
}
.ta-icon-towbar-parts:before {
  content: "\e217";
}
.ta-icon-wheel-drive:before {
  content: "\e218";
}
.ta-icon-vin-number:before {
  content: "\e219";
}
.ta-icon-skype:before {
  content: "\e220";
}
.ta-icon-gplus:before {
  content: "\e221";
}
.ta-icon-gplus-squared:before {
  content: "\e222";
}
.ta-icon-calendar:before {
  content: "\e223";
}
.ta-icon-calendar-plus-o:before {
  content: "\e224";
}
.ta-icon-calendar-minus-o:before {
  content: "\e225";
}
.ta-icon-calendar-times-o:before {
  content: "\e226";
}
.ta-icon-calendar-check-o:before {
  content: "\e227";
}
.ta-icon-route:before {
  content: "\e228";
}
.ta-icon-earth:before {
  content: "\e229";
}
.ta-icon-warranty:before {
  content: "\e230";
}
.ta-icon-item-part-number:before {
  content: "\e231";
}
.ta-icon-comparable-number:before {
  content: "\e232";
}
.ta-icon-oe-number:before {
  content: "\e233";
}
.ta-icon-trade-number:before {
  content: "\e234";
}
.ta-icon-comment:before {
  content: "\e235";
}
.ta-icon-chat:before {
  content: "\e236";
}
.ta-icon-filtering:before {
  content: "\e237";
}
.ta-icon-cw:before {
  content: "\e238";
}
.ta-icon-print:before {
  content: "\e239";
}
.ta-icon-fuel-supply-system:before {
  content: "\e240";
}
.ta-icon-universal-part:before {
  content: "\e241";
}
.ta-icon-gtin-number:before {
  content: "\e242";
}
.ta-icon-360-view:before {
  content: "\e243";
}
.ta-icon-price:before {
  content: "\e244";
}
.ta-icon-calculator:before {
  content: "\e245";
}
.ta-icon-open-window:before {
  content: "\e246";
}
.ta-icon-resize-full-alt:before {
  content: "\e247";
}
.ta-icon-upload:before {
  content: "\e248";
}
.ta-icon-steering-linkage:before {
  content: "\e249";
}
.ta-icon-power-take-off:before {
  content: "\e250";
}
.ta-icon-crank-drive:before {
  content: "\e251";
}
.ta-icon-crank-case:before {
  content: "\e252";
}
.ta-icon-hydraulic-system:before {
  content: "\e253";
}
.ta-icon-mileage:before {
  content: "\e254";
}
.ta-icon-handshake:before {
  content: "\e255";
}
.ta-icon-gaskets:before {
  content: "\e256";
}
.ta-icon-fifth-wheel-coupling:before {
  content: "\e257";
}
.ta-icon-load-securing:before {
  content: "\e258";
}
.ta-icon-cylinder-heads:before {
  content: "\e259";
}
.ta-icon-piston:before {
  content: "\e260";
}
.ta-icon-crankshaft:before {
  content: "\e261";
}
.ta-icon-camshaft:before {
  content: "\e262";
}
.ta-icon-water-pump:before {
  content: "\e263";
}
.ta-icon-engine-mounting:before {
  content: "\e264";
}
.ta-icon-lubrication:before {
  content: "\e265";
}
.ta-icon-air-filter:before {
  content: "\e266";
}
.ta-icon-oxygen-sensor:before {
  content: "\e267";
}
.ta-icon-tuning:before {
  content: "\e268";
}
.ta-icon-bolt-nut:before {
  content: "\e269";
}
.ta-icon-instruments:before {
  content: "\e270";
}
.ta-icon-mirror:before {
  content: "\e271";
}
.ta-icon-download:before {
  content: "\e272";
}
.ta-icon-fleet:before {
  content: "\e273";
}
.ta-icon-workshop:before {
  content: "\e274";
}
.ta-icon-angle-double-left:before {
  content: "\e275";
}
.ta-icon-angle-double-right:before {
  content: "\e276";
}
.ta-icon-angle-double-up:before {
  content: "\e277";
}
.ta-icon-angle-double-down:before {
  content: "\e278";
}
.ta-icon-resize-small:before {
  content: "\e280";
}
.ta-icon-resize-full:before {
  content: "\e281";
}
.ta-icon-ean-number:before {
  content: "\e282";
}
.ta-icon-marketplace:before {
  content: "\e283";
}
.ta-icon-down-big:before {
  content: "\e284";
}
.ta-icon-up-big:before {
  content: "\e285";
}
.ta-icon-left-big:before {
  content: "\e286";
}
.ta-icon-right-big:before {
  content: "\e287";
}
.ta-icon-share:before {
  content: "\e288";
}
.ta-icon-chart-line:before {
  content: "\e289";
}
.ta-icon-translate:before {
  content: "\e290";
}
.ta-icon-tecdoc-type-number:before {
  content: "\e291";
}
.ta-icon-support:before {
  content: "\e292";
}
.ta-icon-products:before {
  content: "\e293";
}
.ta-icon-recommended:before {
  content: "\e294";
}
.ta-icon-cv-and-pc:before {
  content: "\e295";
}
.ta-icon-lcv-and-pc:before {
  content: "\e296";
}
.ta-icon-ellipsis:before {
  content: "\e297";
}
.ta-icon-ellipsis-vert:before {
  content: "\e298";
}
.ta-icon-to-do-list:before {
  content: "\e299";
}
.ta-icon-vehicle-coverage:before {
  content: "\e300";
}
.ta-icon-bookmark:before {
  content: "\e301";
}
.ta-icon-bookmark-empty:before {
  content: "\e302";
}
.ta-icon-star-half-alt:before {
  content: "\e303";
}
.ta-icon-newspaper:before {
  content: "\e304";
}
.ta-icon-add-user:before {
  content: "\e305";
}
.ta-icon-remove-user:before {
  content: "\e306";
}
.ta-icon-pin:before {
  content: "\e307";
}
.ta-icon-wifi:before {
  content: "\e308";
}
.ta-icon-sitemap:before {
  content: "\e309";
}
.ta-icon-windows:before {
  content: "\e310";
}
.ta-icon-apple:before {
  content: "\e311";
}
.ta-icon-amazon:before {
  content: "\e312";
}
.ta-icon-chrome:before {
  content: "\e313";
}
.ta-icon-firefox:before {
  content: "\e314";
}
.ta-icon-internet-explorer:before {
  content: "\e315";
}
.ta-icon-edge:before {
  content: "\e316";
}
.ta-icon-android:before {
  content: "\e317";
}
.ta-icon-graduation-cap:before {
  content: "\e318";
}
.ta-icon-instagram:before {
  content: "\e319";
}
.ta-icon-cc-paypal:before {
  content: "\e320";
}
.ta-icon-cc-visa:before {
  content: "\e321";
}
.ta-icon-trello:before {
  content: "\e322";
}
.ta-icon-bluetooth:before {
  content: "\e323";
}
.ta-icon-cc:before {
  content: "\e324";
}
.ta-icon-dropbox:before {
  content: "\e325";
}
.ta-icon-angle-left:before {
  content: "\e326";
}
.ta-icon-angle-right:before {
  content: "\e327";
}
.ta-icon-angle-up:before {
  content: "\e328";
}
.ta-icon-angle-down:before {
  content: "\e329";
}
.ta-icon-close-alt:before {
  content: "\e330";
}
.ta-icon-history:before {
  content: "\e331";
}
.ta-icon-megaphone:before {
  content: "\e333";
}
.ta-icon-unlink-alt:before {
  content: "\e334";
}
.ta-icon-link-alt:before {
  content: "\e335";
}
.ta-icon-drag-drop:before {
  content: "\e336";
}
.ta-icon-back:before {
  content: "\e337";
}
.ta-icon-forward:before {
  content: "\e338";
}
.ta-icon-undo:before {
  content: "\e339";
}
.ta-icon-redo:before {
  content: "\e340";
}
.ta-icon-idp:before {
  content: "\e341";
}
.ta-icon-save-close:before {
  content: "\e342";
}
.ta-icon-no-filtering:before {
  content: "\e343";
}
.ta-icon-add-column:before {
  content: "\e344";
}
.ta-icon-delete-column:before {
  content: "\e345";
}
.ta-icon-add-row:before {
  content: "\e346";
}
.ta-icon-delete-row:before {
  content: "\e347";
}
.ta-icon-manual-searching:before {
  content: "\e348";
}
.ta-icon-advanced-searching:before {
  content: "\e349";
}
.ta-icon-free-text-search:before {
  content: "\e350";
}
.ta-icon-compass:before {
  content: "\e351";
}
.ta-icon-delivery:before {
  content: "\e352";
}
.ta-icon-menu:before {
  content: "\e353";
}
.ta-icon-tecalliance-cube:before {
  content: "\e8a8";
}
.ta-icon-catalytic-converter:before {
  content: "\e900";
}
.ta-icon-master-data-export:before {
  content: "\e901";
}
.ta-icon-snowflake:before {
  content: "\e902";
}
.ta-icon-sun:before {
  content: "\e903";
}
.ta-icon-all-weather:before {
  content: "\e904";
}
.ta-icon-offroad:before {
  content: "\e905";
}
.ta-icon-columns:before {
  content: "\e906";
}
.ta-icon-rows:before {
  content: "\e907";
}
.ta-icon-bulleye:before {
  content: "\e908";
}
.ta-icon-bulleye-alt:before {
  content: "\e909";
}
.ta-icon-feedback:before {
  content: "\e90a";
}
.ta-icon-box:before {
  content: "\e90b";
}
.ta-icon-master-data-vehicles:before {
  content: "\e90c";
}
.ta-icon-master-data-articles:before {
  content: "\e90d";
}
.ta-icon-doughnut:before {
  content: "\e90e";
}
.ta-icon-horizontal-bar-chart:before {
  content: "\e90f";
}
.ta-icon-line-chart-interpolation:before {
  content: "\e910";
}
.ta-icon-map:before {
  content: "\e911";
}
.ta-icon-tree-map:before {
  content: "\e912";
}
.ta-icon-ccw:before {
  content: "\e913";
}
.ta-icon-unpin:before {
  content: "\e914";
}
.ta-icon-maximize:before {
  content: "\e915";
}
.ta-icon-minimize:before {
  content: "\e916";
}
.ta-icon-inherited-data:before {
  content: "\e917";
}
.ta-icon-assets:before {
  content: "\e918";
}
.ta-icon-remove-items:before {
  content: "\e919";
}
.ta-icon-master-data-update:before {
  content: "\e91a";
}
.ta-icon-linked-items:before {
  content: "\e920";
}
.ta-icon-honeycomb:before {
  content: "\e921";
}
.ta-icon-aces:before {
  content: "\e922";
}
.ta-icon-pies:before {
  content: "\e923";
}
.ta-icon-forbidden:before {
  content: "\e91d";
}
.ta-icon-google-play:before {
  content: "\e91e";
}
.ta-icon-one-data-loader:before {
  content: "\e91f";
}
.ta-icon-accordions-expand:before {
  content: "\e924";
}
.ta-icon-accordions-collapse:before {
  content: "\e925";
}
.ta-icon-ai:before {
  content: "\e926";
}
.ta-icon-backorder:before {
  content: "\e927";
}
.ta-icon-autocare:before {
  content: "\e928";
}
.ta-icon-cv-id:before {
  content: "\e929";
}
.ta-icon-text-blocks:before {
  content: "\e92a";
}
.ta-icon-maintenance:before {
  content: "\e92b";
}
.ta-icon-circle-notch:before {
  content: "\e92c";
}
.ta-icon-edit-alt:before {
  content: "\e92d";
}
.ta-icon-exchange:before {
  content: "\e92e";
}
.ta-icon-hand-pointer:before {
  content: "\e92f";
}
.ta-icon-play:before {
  content: "\e930";
}
.ta-icon-rocket:before {
  content: "\e931";
}
.ta-icon-puzzle:before {
  content: "\e932";
}
.ta-icon-sensitive-graphic:before {
  content: "\e933";
}
.ta-icon-merged-fields:before {
  content: "\e934";
}
