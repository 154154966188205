/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";

@import 
  //TA-BOOTSTRAP
  "ta-bootstrap4/dist/css/ta-bootstrap.css",
  "ta-bootstrap4/dist/css/ta-bootstrap-grid.css",
  "ta-bootstrap4/dist/css/ta-bootstrap-reboot.css",
  //FONTAWESOME
  "font-awesome/css/font-awesome.css",
  //PRIMENG
  "primeicons/primeicons.css",
  "primeng/resources/primeng.min.css",
  //AGGRID
  "ag-grid-community/styles/ag-grid.css",
  "ag-grid-community/styles/ag-theme-balham.css", 
  //APP
  "./scss/variables",
  "./scss/variables2",
  "./scss/fonts",
  "./scss/general",
  "./scss/sidebar",  
  "./scss/ag-grid/balham", //aggrid customizations
  "./scss/ta-primeng/primeng.scss",  // primeng customizations
  // ONE
  "./scss/one/buttons",
  "./scss/one/dialog",
  "./scss/one/menu",
  "./scss/one/form",
  "./scss/one/scrollbar"; 
